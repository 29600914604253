body {
    color: #1C304C;
    font-family: 'Mulish', sans-serif;
}

.form-label-style label {
    color: #1C304C;
    font-family: 'Mulish', sans-serif;
}

.vh-70 {
    min-height: 70vh;
}

.vh-stretch {
    min-height: calc(100vh - 60px);
}

.z-1 {
    z-index: 1;
}

.w-15 {
    width: 15%;
}

.w-10 {
    width: 10%;
}

.icon-48 {
    width: 48px;
}

.icon-88 {
    width: 88px;
}

.logo {
    width: 260px;
}

.logo-mobile {
    width: 100px;
}

.vr-bps {
    max-width: .5px;
    border: .5px solid #65687D;
}

.sponsor-200 {
    width: 100%;
    max-width: 200px;
}

.bps-card-shadow {
    box-shadow: 0px 4px 20px hsla(0, 100%, 0%, 0.1) !important;
}

/* Inputs */

.ant-input-search .ant-input:hover, .ant-input-search .ant-input:focus, .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover, .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color: #1C304C !important;
    box-shadow: none !important;
}

/* Buttons */

.bps-icon-btn {
    color: #4787C2 !important;
    border: none !important;
    border-radius: 15px;
    padding: 5px;
}

.bps-icon-btn:hover {
    color: #1C304C !important;
    background-color: #F0F4FA !important;
    border: none !important;
}

.ant-btn-primary {
    border: 1px solid #1C304C;
    background-color: transparent;
    color: #1C304C;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
    border: 1px solid #4787C2;
    background-color: #4787C2;
}

.bps-primary-button, .bps-primary-button:focus {
    background-color: #1c304c;
    color: #ffffff !important;
    border: none;
    border-radius: 0%;
    font-size: 18px;
    font-weight: 500;
    padding: .5rem 1.5rem;
    transition: .25s ease-in-out;
    text-align: center;
}

.bps-primary-button:hover, .bps-primary-button:active {
    background-color: #4787C2;
    transition: .25s ease-in-out;
    box-shadow: none !important;
}

.bps-secondary-button, .bps-secondary-button:focus {
    background-color: #ffffff !important;
    border: none;
    outline: 1px solid #1C304C;
    outline-offset: -1px;
    color: #1C304C !important;
    border-radius: 0%;
    font-size: 18px;
    font-weight: 500;
    padding: .5rem 1.5rem;
    transition: .25s ease-in-out;
}

.bps-secondary-button:hover, .bps-secondary-button:active {
    background-color: #4787C2 !important;
    color: #ffffff !important;
    outline: 1px solid #4787C2;
    outline-offset: -1px;
    box-shadow: none !important;
    transition: .25s ease-in-out;
}

.bps-secondary-button-sm, .bps-secondary-button-sm:focus {
    background-color: #ffffff !important;
    border: none;
    outline: 1px solid #1C304C;
    outline-offset: -1px;
    color: #1C304C !important;
    border-radius: 0%;
    font-size: 14px;
    font-weight: 500;
    padding: .25rem 1rem;
    transition: .25s ease-in-out;
}

.bps-secondary-button-sm:hover, .bps-secondary-button-sm:active {
    background-color: #4787C2 !important;
    color: #ffffff !important;
    outline: 1px solid #4787C2;
    outline-offset: -1px;
    box-shadow: none !important;
    transition: .25s ease-in-out;
}

.bps-dashed-button, .bps-dashed-button:focus {
    background-color: #fafafa !important;
    border: none;
    outline: 1px dashed #65687D;
    outline-offset: -1px;
    color: #65687D !important;
    border-radius: 0%;
    font-size: 14px;
    font-weight: 500;
    padding: 2rem 1rem;
    box-shadow: none !important;
    transition: .25s ease-in-out;
}

.bps-dashed-button:hover, .bps-dashed-button:active {
    background-color: #f5f5f5 !important;
    outline: 1px dashed #1C304C;
    outline-offset: -1px;
    box-shadow: none !important;
    transition: .25s ease-in-out;
}

.bps-link-button {
    background-color: transparent;
    color: #4787C2 !important;
    border: none;
    padding: 0px;
}

.bps-link-button:hover, .bps-link-button:active, .bps-link-button:focus  {
    background-color: transparent;
    color: #1C304C !important;
    border: none;
    box-shadow: none;
}

.arrow-hover {
    transition: .2s;
}

.arrow-hover:hover {
    padding-right: 10px;
    transition: .2s;
}

.card-link {
    color: #1C304C;
    transition: ease-in-out .2s;
}

.card-link:hover {
    box-shadow: 0px 8px 40px hsla(0, 100%, 0%, 0.1) !important;
    transition: ease-in-out .2s;
    border: 1px solid #1C304C;
}

.fave-link {
    color: #1C304C;
    background-color: #ffffff;
    border: 1px solid #ffffff;
}

.fave-link:hover, .fave-link:active, .fave-link:focus {
    border: 1px solid #1C304C;
    background-color: #ffffff !important;
    box-shadow: none !important;
}


/* Backgrounds */

.bps-bg-light {
    background-color: #FAF5F0;
}

.bps-bg-wave-top {
    overflow: hidden;
}

.bps-bg-wave-bottom {
    overflow: hidden;
    transform: rotate(180deg);
}

.bps-card {
    box-shadow: 0px 8px 40px hsla(0, 100%, 0%, 0.1) !important;
}

.bps-bg-midnight {
    background-color: #1C304C;
}

.bps-bg-light-sky {
    background-color: #D4DEEF;
}

/* Colors */

.bps-midnight {
    color: #1C304C !important;
}

.bps-midnight-tint {
    color: #65687D !important;
}

.bps-sky {
    color: #4787C2 !important;
}

.bps-live {
    color: #86BF82 !important;
}


/* Tables */

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: transparent !important;
}

table:hover,
tr:hover,
thead:hover {
    background: none !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
    background: #ffffff !important;
}

.ant-table-cell  {
    background-color: #ffffff !important;
}


.staging-link {
    padding: 4px 10px;
    margin-left: -10px;
    color: #4787C2;
}

a.staging-link:hover {
    background-color: #F0F4FA !important;
    color: #1C304C;
    border-radius: 14px;
}

.live-link {
    padding: 4px 10px;
    margin-left: -10px;
    color: #86BF82;
}

a.live-link:hover {    
    padding: 4px 10px;
    margin-left: -10px;
    color: #578254;
    background-color: #E3FFD4;
    border-radius: 14px;
}

.live-staging {
    text-transform: uppercase;
    color: #B3B3B3;
    font-size: .8em;
}

.nav-pills .nav-link {
    color: #65687D !important;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: #F0F4FA !important;
    color: #65687D !important;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link, .nav-pills .nav-link:hover {
    background-color: #F0F4FA !important;
    color: #1C304C !important;
}

/* Typography */

p {
    font-size: 1.25em;
    font-weight: 400;
  }
  

.fs-60 {
    font-size: 3.75em;
    font-weight: 900 !important;
}

.fs-48 {
    font-size: 3em;
    font-weight: 700 !important;
}

.fs-36 {
    font-size: 2.25em;
    font-weight: 700 !important;
}

.fs-26 {
    font-size: 1.625em;
    font-weight: 700 !important;
}

.fs-20 {
    font-size: 1.25em;
    font-weight: 700 !important;
}

.fs-18 {
    font-size: 1.125em;
}

.fs-16 {
    font-size: 1em;
}

.cursor-pointed{
    cursor: pointer;
}