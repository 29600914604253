.logo {
    width: 300px;
}

.logo-mobile {
    width: 110px;
}

.nav-divider {
    background-color: #65687D;
    width: 1px;
    margin-top: 9px;
    height: 20px;
}

.nav-links {
    color: #65687D !important;
}

.navbar-light .navbar-nav .nav-link {
    color: #65687D !important;
    border-bottom: 1px solid #ffffff;
    transition: .25s ease-in-out;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: #1c304c !important;
    border-bottom: 1px solid #1c304c;
    background-color: #ffffff !important;
}

.primary-nav-links:active {
    background-color: white;
    color: #1c304c !important;
    border-bottom: 1px solid #1c304c;
}

.dropdown-menu {
    border: none;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.dropdown-item {
    color: #65687D !important;
}

.dropdown-item.active, .dropdown-item:active, .dropdown-item:hover {
    background-color: #e9ecef !important;
    color: #1c304c !important;
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
    background-color: #ffffff !important;
    color: #1c304c !important;
    border-bottom: 1px solid #1c304c;
}